'use client';
import Image from 'next/image';
import { Bebas_Neue } from 'next/font/google';
import { Button } from '@/components/ui/button';
import { cn, copyToClipboard } from '@/lib/utils';
import { ArrowRight, Check, Youtube } from 'lucide-react';
import Faq from '@/components/faq';
import Banner from '@/components/banner';
import { motion } from 'framer-motion';
import HeroImage from '@/components/heroImage'
import ImageCarousel from '@/components/imageCarousel'
import { Card } from '@/components/ui/card';

const headFont = Bebas_Neue({
  weight: ['400'],
  subsets: ['latin'],
});

export default function Home() {
  const dressImages = [
    'https://utfs.io/f/8f74caf9-d9a5-44bf-8560-4cbc6e742b91-lw2d5y.png',
    'https://utfs.io/f/56b81ee2-299e-4e96-a8d2-3183087801d0-lw2d5x.png',
    'https://utfs.io/f/170983fe-a884-458d-bf39-cdbfdd4401b3-lw2d5w.png',
    'https://utfs.io/f/10ffb73a-ce53-4ea8-97fc-f6e8b4f2d810-lw2d5v.png',
    'https://utfs.io/f/e41ee42a-232f-402a-ad69-22dd5a526007-lw2d5u.png',
    'https://utfs.io/f/3a8d9fcb-699e-4843-9d1a-b48ea2538ced-lw2d5t.png',
    'https://utfs.io/f/13b6f820-01b0-4833-b435-ca6041f47638-lw2d5s.png',
    'https://utfs.io/f/fe8fc849-69a9-4ed3-8771-226525a0d574-lw2d5r.png',
    'https://utfs.io/f/24e563d0-65d8-44ba-990b-518e66ca33f6-lw2d5q.png',
    'https://utfs.io/f/420e25b7-358b-46ec-958b-9b4825fb3580-vp37o6.png',
  ];

  const modelImages = [
    'https://utfs.io/f/fb5db5ee-640d-478a-a46a-0d6e83d6d250-hoc2xk.png',
    'https://utfs.io/f/09470b62-0d4f-40d1-842b-a038cbad119a-hoc2xj.png',
    'https://utfs.io/f/de5e2ba7-d3ae-4eb2-a5dd-c577836ddb55-hoc2xi.png',
    'https://utfs.io/f/e6ce9004-696d-44bd-af4b-48838ab4a764-hoc2xh.png',
    'https://utfs.io/f/5253934c-3cfd-47b9-9d7f-3576924b4794-hoc2xg.png',
    'https://utfs.io/f/0e9c1e3d-2d7a-4473-a33b-37dac3e8925f-hoc2xf.png',
    'https://utfs.io/f/a6af69bd-f7b3-4cb6-bae9-ea3fde032718-hoc2xe.png',
    'https://utfs.io/f/e34c80be-699e-4883-9f4f-3c12f65f3a58-hoc2xd.png',
    'https://utfs.io/f/9685300b-7916-4188-8ba3-8495cf79e6c3-hoc2xc.png',
    'https://utfs.io/f/0d846f88-8253-4083-8d23-93b442bb832a-kahwy0.png',
  ];

  return (
    <div className="flex flex-col items-center mx-auto">
      {/* Hero */}
      <section className="w-full py-10 lg:py-20 border-b border-background px-4">
        <div
          className="flex flex-col xl:flex-row mx-auto max-w-7xl px-4 xl:px-0 gap-6 xl:gap-0 items-center text-center xl:items-start xl:text-left"
        >
          <div className="flex flex-col gap-6 sm:gap-8">
            <Banner text={<p>News: The <strong>FASHN API is now live!</strong> Access it through your profile settings.</p>} />
            <h1
              className={cn(
                'text-4xl sm:text-7xl md:text-8xl font-semibold',
                headFont.className
              )}
            >
              AI photography for fashion brands
            </h1>
            <p className="md:leading-8 sm:text-lg">
              Your clothes and models don’t have to be in the same room. <br />
              FASHN enables the transfer of clothing from image to image.
            </p>
            <div className="grid xl:grid-cols-2 w-fit gap-3 xl:gap-7 mx-auto xl:mx-0">

              <a href='https://app.fashn.ai'>
                <Button variant={'special'} className='w-full group flex gap-2 items-center'>Try Now for Free

                  <ArrowRight
                    size={18}
                    className="group-hover:translate-x-1 transition-all"
                  />
                </Button>
              </a>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: 'easeOut' }}
            className='flex justify-center xl:justify-end'>
            <HeroImage />
          </motion.div>
        </div>
      </section>
      {/* How it works */}
      <section
        className="text-center py-10 md:py-20 w-full bg-foreground flex flex-col gap-10 md:gap-20"
        id="how-it-works"
      >
        <h2 className={cn(headFont.className, 'text-2xl tracking-widest')}>
          How it works
        </h2>
        <div className="max-w-7xl flex flex-col gap-10 md:gap-20 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 text-left p-4 xl:p-0">
            <div className="flex flex-col gap-8">
              <div className="bg-background w-full h-[1px]"></div>
              <h3
                className={cn(
                  headFont.className,
                  'text-2xl md:text-3xl lg:text-4xl'
                )}
              >
                1. &nbsp; Choose model & clothes
              </h3>
            </div>
            <div className="flex flex-col gap-8">
              <div className="bg-background w-full h-[1px]"></div>
              <h3
                className={cn(
                  headFont.className,
                  'text-2xl md:text-3xl lg:text-4xl'
                )}
              >
                2. &nbsp; Set clothing type & options
              </h3>
            </div>
            <div className="flex flex-col gap-8">
              <div className="bg-background w-full h-[1px]"></div>
              <h3
                className={cn(
                  headFont.className,
                  'text-2xl md:text-3xl lg:text-4xl'
                )}
              >
                3. &nbsp; Generate image
              </h3>
            </div>
          </div>
          {/* Video */}
          <div className="group w-full max-w-[680px] lg:w-[680px] h-auto md:rounded-lg glass p-2 md:p-4 mx-auto relative">
            <video autoPlay muted loop playsInline className="rounded-md">
              <source src="/dashboard-demo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <a
              target="_blank"
              href="https://youtu.be/QERmozFizy8?si=iXtVYSiNb_kxbMs9"
              className="absolute inset-0 flex justify-center items-center"
            >
              <Button className="hidden group-hover:flex items-center">
                <Youtube className="h-5 w-5 mr-2" /> Watch Full Demo
              </Button>
            </a>
          </div>
        </div>
      </section>

      {/* Showcase */}
      <section className="py-10 md:py-20 w-full bg-[#efefef] flex flex-col gap-10 md:gap-20 items-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { type: 'spring', duration: 0.8 },
          }}
          viewport={{ once: true, amount: 0.8 }}
          className="flex flex-col gap-6 md:gap-10 px-4 lg:px-0"
        >
          <h1
            className={cn(
              'text-4xl lg:text-7xl font-semibold',
              headFont.className
            )}
          >
            Get <span className="opacity-50">more</span> out of your photoshoots
          </h1>
          <p className="md:text-lg max-w-3xl">
            A photoshoot doesn’t have to end in the studio. Leverage your assets
            to produce endless combinations, and create photos that resonate
            with your diverse audience perfectly.
          </p>
        </motion.div>
        <div className="flex flex-col gap-6 md:gap-10 w-full">
          <h2
            className={cn(
              'text-3xl lg:text-5xl font-semibold max-w-7xl flex w-full justify-end mx-auto px-4 lg:px-0',
              headFont.className
            )}
          >
            {' '}
            Shoot your dress once, showcase without limits
          </h2>
          <ImageCarousel images={dressImages} direction='rtl' />
        </div>
        <div className="flex flex-col gap-6 md:gap-10 w-full">
          <h2
            className={cn(
              'text-3xl lg:text-5xl font-semibold max-w-7xl flex w-full justify-start mx-auto px-4 lg:px-0',
              headFont.className
            )}
          >
            Got a favorite model? Reuse that perfect shot
          </h2>
          <ImageCarousel images={modelImages} direction='ltr' />
        </div>
      </section>

      {/* Features */}
      <section
        id="features"
        className="text-center py-10 md:py-20 w-full flex flex-col gap-10 md:gap-20 bg-foreground/80 p-4 md:p-0"
      >
        <h2 className={cn(headFont.className, 'text-2xl tracking-widest')}>
          Features
        </h2>
        {/* Indistinguishable from reality */}
        <div className="flex flex-col gap-20 mx-auto max-w-6xl w-full px-4 lg:px-0">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { type: 'spring', duration: 1 },
            }}
            viewport={{ once: true, amount: 0.5 }}
            className="flex flex-col gap-6 lg:gap-0 md:flex-row justify-between w-full"
          >
            <div className="flex flex-col gap-2 text-left max-w-xl">
              <h1 className={cn(headFont.className, 'text-2xl md:text-4xl')}>
                Indistinguishable from reality
              </h1>
              <p className="md:text-lg max-w-lg">
                Our in-house AI model avoids the typical ‘AI-generated’ look by
                using real images as input. It is carefully trained to preserve
                the details of both the model and the clothes, seamlessly
                blending them to produce photorealistic results.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-3 gap-2 md:gap-4 mx-auto md:mx-0">
                <div className="group relative">
                  <img
                    loading='lazy'
                    src={
                      'https://utfs.io/f/b0ceef27-eed4-4a26-8191-224f907dc9c5-jaehju.png'
                    }
                    alt={'Model with printed shirt 1'}
                    className="w-44 h-auto rounded-md"
                  />
                  <span
                    className={cn(
                      headFont.className,
                      'absolute text-2xl text-foreground bottom-2 left-2',
                      'transform translate-y-5 opacity-0 transition-all duration-300 ease-in-out',
                      'group-hover:translate-y-0 group-hover:opacity-100'
                    )}
                  >
                    FASHN AI
                  </span>
                </div>
                <div className="group relative">
                  <img
                    loading='lazy'
                    src={
                      'https://utfs.io/f/a0d35c3e-a557-477d-a836-e3e26d0bf9cb-jaehjt.png'
                    }
                    alt={'Model with printed shirt 2'}
                    className="w-44 h-auto rounded-md"
                  />
                  <span
                    className={cn(
                      headFont.className,
                      'absolute text-2xl text-foreground bottom-2 left-2',
                      'transform translate-y-5 opacity-0 transition-all duration-300 ease-in-out',
                      'group-hover:translate-y-0 group-hover:opacity-100'
                    )}
                  >
                    FASHN AI
                  </span>
                </div>
                <div className="group relative">
                  <img
                    loading='lazy'
                    src={
                      'https://utfs.io/f/244b4ed6-e1a8-4c46-822d-b09a6f4eab3f-jaehjv.png'
                    }
                    alt={'Model with printed shirt 3'}
                    className="w-44 h-auto rounded-md"
                  />
                  <span
                    className={cn(
                      headFont.className,
                      'absolute text-2xl text-foreground bottom-2 left-2',
                      'transform translate-y-5 opacity-0 transition-all duration-300 ease-in-out',
                      'group-hover:translate-y-0 group-hover:opacity-100'
                    )}
                  >
                    Original
                  </span>
                </div>
              </div>
              <span className={cn(headFont.className, 'text-xl self-end text-primary/80')}>Can you spot the original image?</span>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { type: 'spring', duration: 1 },
            }}
            viewport={{ once: true, amount: 0.8 }}
            className="flex flex-col md:flex-row-reverse gap-6 lg:gap-0 justify-between w-full items-center"
          >
            <div className="flex flex-col gap-2 text-left max-w-xl">
              <h1 className={cn(headFont.className, 'text-2xl md:text-4xl')}>
                Not limited to flat lay images
              </h1>
              <p className="md:text-lg max-w-lg">
                Clothes can be transferred from flat lay product images, but our
                solution excels at taking clothes from images of people wearing
                them.
              </p>
            </div>
            <div className="flex gap-2 md:gap-4 items-center">
              <img
                loading='lazy'
                src={
                  'https://utfs.io/f/b3c72be6-d7e1-4f9d-8822-d02db0fae478-859rfx.png'
                }
                alt={'Original model'}
                className="w-24 md:w-40 h-auto rounded-md"
              />

              <div className="flex flex-col gap-2 md:gap-4">
                <div className="grid grid-cols-2 gap-2 md:gap-4">
                  <img
                    loading='lazy'
                    src={
                      'https://utfs.io/f/91d4a457-dd9a-40b5-be8f-f36a12bef27b-x2iqih.png'
                    }
                    alt={'Flat lay tshirt'}
                    className="w-40 h-auto rounded-md"
                  />
                  <img
                    loading='lazy'
                    src={
                      'https://utfs.io/f/562ffbd4-57ec-4828-b012-9aa5212717b8-qvbzv4.png'
                    }
                    alt={'Model result'}
                    className="w-40 h-auto rounded-md"
                  />
                </div>
                <div className="grid grid-cols-2 gap-2 md:gap-4">
                  <img
                    loading='lazy'
                    src={
                      'https://utfs.io/f/53336690-0576-420b-b4ca-d61ef99d26bc-drizo3.png'
                    }
                    alt={'Model with dress'}
                    className="w-40 h-auto rounded-md"
                  />
                  <img
                    loading='lazy'
                    src={
                      'https://utfs.io/f/93ecd279-bbae-4179-9fdb-f1c102ded5b5-hl78ee.png'
                    }
                    alt={'Model result'}
                    className="w-40 h-auto rounded-md"
                  />
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { type: 'spring', duration: 1 },
            }}
            viewport={{ once: true, amount: 0.8 }}
            className="flex flex-col md:flex-row gap-6 lg:gap-0 justify-between w-full"
          >
            <div className="flex flex-col gap-2 text-left max-w-xl">
              <h1 className={cn(headFont.className, 'text-2xl md:text-4xl')}>
                Advanced transformations
              </h1>
              <p className="md:text-lg max-w-lg">
                Supporting a wide range of input combinations such as different
                genders, ethnicities, body shapes, poses and shot perspectives.{' '}
              </p>
            </div>
            <div className="grid grid-cols-3 gap-2 md:gap-4 mx-auto lg:mx-0">
              <img
                loading='lazy'
                src={
                  'https://utfs.io/f/f2498963-5fb0-4639-865d-acfa29fada87-1lr7cd.png'
                }
                alt={'Model original'}
                className="w-48 h-auto rounded-md"
              />
              <img
                loading='lazy'
                src={
                  'https://utfs.io/f/b49f0e51-b376-4e63-8e82-fd724a0e93e1-qnroca.png'
                }
                alt={'Model with dress'}
                className="w-48 h-auto rounded-md"
              />
              <img
                loading='lazy'
                src={
                  'https://utfs.io/f/83b9bb07-fb53-4bac-b80c-f5866c94f173-j2onjb.png'
                }
                alt={'Model result'}
                className="w-48 h-auto rounded-md"
              />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Pricing */}
      <section
        id="pricing"
        className="text-center py-10 md:py-20 w-full flex flex-col gap-10 md:gap-20 px-4 lg:px-0"
      >
        <h2 className={cn(headFont.className, 'text-2xl tracking-widest')}>
          Pricing
        </h2>
        <div className="flex mx-auto max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <Card className="flex flex-col gap-4 text-left max-w-xs p-6">
              <span className="px-2 py-1 text-sm font-medium w-fit border border-primary rounded-md">
                Basic Plan
              </span>
              <p>
                {' '}
                <span className="text-4xl font-bold">$9</span> / month
              </p>
              <a href={'https://app.fashn.ai'}>
                <Button
                  variant={'outline'}
                  className="group flex gap-2 items-center w-full"
                >
                  Start Now
                  <ArrowRight
                    size={18}
                    className="group-hover:translate-x-1 transition-all"
                  />
                </Button>
              </a>
              <ul className="text-sm flex flex-col gap-2">
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Platform access
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Up to 200 images
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Saved generation history
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Shareable public links
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Ticket-based support
                </li>
              </ul>
            </Card>
            <Card className="flex flex-col gap-4 text-left max-w-xs shadow-xl p-6 border-2">
              <span className="rounded-md px-2 py-1 text-sm font-medium w-fit bg-special">
                Pro Plan
              </span>
              <p>
                {' '}
                <span className="text-4xl font-bold">$59</span> / month
              </p>
              <a href={'https://app.fashn.ai'}>
                <Button
                  variant={'special'}
                  className="group flex gap-2 items-center w-full"
                >
                  Start Now
                  <ArrowRight
                    size={18}
                    className="group-hover:translate-x-1 transition-all"
                  />
                </Button>
              </a>
              <ul className="text-sm flex flex-col gap-2">
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Everything in Basic Plan
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Unlimited platform generations
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Generate AI models (with FLUX)
                </li>
                {/* <li className="flex gap-2 items-center">
                  <Check size={14} /> Clothes color changer
                </li> */}
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Prioritized feature requests
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Direct channel support
                </li>
              </ul>
            </Card>
            <Card className="flex flex-col gap-4 text-left max-w-xs p-6">
              <span className="bg-primary text-foreground rounded-md px-2 py-1 text-sm font-medium w-fit">
                Enterprise Plan
              </span>
              <p className="text-4xl font-bold">Custom</p>
              <ul className="text-sm flex flex-col gap-2">
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Dedicated API endpoint
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Unlimited* image generation
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Custom AI (fine-tuned on your images)
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Prioritized feature requests
                </li>
                <li className="flex gap-2 items-center">
                  <Check size={14} /> Direct channel support
                </li>
              </ul>
              <p className="text-xs mt-auto">* Subject to rate limiting</p>

              <div className="h-10 px-4 py-2 border bg-primary text-foreground rounded-md mt-auto text-center">
                <span>
                  Contact us at{' '}
                  <Button
                    className="p-0 h-fit"
                    variant={'ghost'}
                    onClick={() => copyToClipboard('info@fashn.ai')}
                  >
                    info@fashn.ai
                  </Button>
                </span>
              </div>
            </Card>
          </div>
        </div>
      </section>
      {/* FAQ */}
      <Faq />
    </div>
  );
}
