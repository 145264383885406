import { type ClassValue, clsx } from "clsx"
import { toast } from "sonner";
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function uppercaseFirstLetter(str: string) {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function formatDate(isoDateString: string, utcOffset: number = 0) {
  const date = new Date(isoDateString);

  // Adjust for UTC offset
  date.setMinutes(date.getMinutes() + utcOffset);

  // Convert to "Month Day, Year" format
  const formattedDate = date.toLocaleDateString("en-US", {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  return formattedDate;
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Email copied to clipboard!')
  } catch (err) {
    console.error("Failed to copy!", err);
  }
};