import * as React from 'react';

const Banner: React.FunctionComponent<{ text: any }> = ({ text }) => {
    return (
        <div className='bg-special rounded-full py-2 px-4 text-sm hidden sm:block text-primary w-fit mx-auto xl:mx-0'>
            {text}
        </div>
    )
};

export default Banner;
