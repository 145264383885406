import { cn } from '@/lib/utils';
import * as React from 'react';

interface ImageCarouselProps {
    images: string[],
    direction: 'ltr' | 'rtl'
}

const ImageCarousel: React.FunctionComponent<ImageCarouselProps> = ({ images, direction }) => {
    return (
        <div className="overflow-y-scroll sm:overflow-hidden relative w-full smooth-scroll">
            <div className={cn(`${direction === 'ltr' ? ' animate-slide-right' : 'animate-slide-left'}`)}>
                <div className="inline-flex gap-4">
                    {images.concat(images).map((image, index) => (
                        <img
                            loading='lazy'
                            key={index}
                            src={image}
                            alt={`model-${index}`}
                            className="w-48 h-auto rounded-md"
                        />
                    ))}
                </div>
            </div>
        </div>
    )
};

export default ImageCarousel;
