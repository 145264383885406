import * as React from 'react';
import Image from 'next/image';
import { cn } from '@/lib/utils';
import { Bebas_Neue } from 'next/font/google';

const headFont = Bebas_Neue({
  weight: ['400'],
  subsets: ['latin'],
});

const HeroImage: React.FunctionComponent = () => {
  return (
    <div className="grid grid-cols-3 gap-2 md:gap-4">
      <Image
        priority
        src={
          'https://utfs.io/f/e963c78a-76fc-409b-a603-9618f3949e54-1n1ilz.png'
        }
        alt={'demo'}
        width={255}
        height={333}
        className="rounded-md"
      />
      <Image
        priority
        src={
          'https://utfs.io/f/a6bc83c6-28af-433e-b517-a46ced19a28c-1n1im0.png'
        }
        alt={'demo'}
        width={255}
        height={333}
        className="rounded-md"
      />
      <div className="relative h-auto">
        <Image
          priority
          src={
            'https://utfs.io/f/8f22d5d5-210e-4f4c-b3ec-9f612f01499b-1n1im1.png'
          }
          alt={'demo'}
          width={255}
          height={333}
          className="rounded-md"
        />
        <span
          className={cn(
            headFont.className,
            'hidden md:block bg-primary px-2 py-1 text-foreground rounded-br-md absolute bottom-0 right-0 tracking-wide'
          )}
        >
          Full outfit swap
        </span>
      </div>
      <Image
        priority
        src={
          'https://utfs.io/f/29367edc-0528-4b87-9bdf-4651fdf8b2ae-1n1im2.png'
        }
        alt={'demo'}
        width={255}
        height={333}
        className="rounded-md"
      />
      <Image
        priority
        src={
          'https://utfs.io/f/9a16a87d-e7da-4cff-b97d-a29724f08dbe-1n1im3.png'
        }
        alt={'demo'}
        width={255}
        height={333}
        className="rounded-md"
      />
      <div className="relative h-auto">
        <Image
          priority
          src={
            'https://utfs.io/f/95d63cb2-640d-485e-a166-4fd42956b597-1n1im4.png'
          }
          alt={'demo'}
          width={255}
          height={333}
          className="rounded-md"
        />
        <span
          className={cn(
            headFont.className,
            'hidden md:block bg-primary px-2 py-1 text-foreground rounded-br-md absolute bottom-0 right-0 tracking-wide'
          )}
        >
          Top swap
        </span>
      </div>
    </div>
  );
};

export default HeroImage;
