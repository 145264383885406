import { cn, copyToClipboard } from '@/lib/utils';
import { Bebas_Neue } from 'next/font/google';
import * as React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';
import { Button } from './ui/button';
import Image from 'next/image';

const headFont = Bebas_Neue({
  weight: ['400'],
  subsets: ['latin'],
});

const faqModel =
  'https://utfs.io/f/cdcc9903-9384-4153-8337-de55a6247643-7tqape.png';
const faqGarment =
  'https://utfs.io/f/1c5f45c9-7268-4d8d-93bb-28ed61c910a1-w308sh.png';
const faqResult =
  'https://utfs.io/f/927e3fba-0dab-4426-bf33-290b08259458-vqb4qc.png';

const questions = [
  {
    q: 'What technology are you using?',
    a: 'FASHN uses a custom diffusion architecture, trained from scratch on an internal dataset comprising millions of fashion-related images, specifically designed for clothes transfer tasks.',
  },
  {
    q: 'How accurate are the results?',
    a: 'Our solution frequently achieves pixel-perfect, accurate transfers, capturing intricate details of clothing images. However, due to the inherent randomness of the method and biases in the dataset, some generations may not succeed on the first attempt and could require further tries to achieve satisfactory results.',
  },
  {
    // Access to the API is included with each of our subscription plans.
    q: 'Can I use the FASHN API to power my app?',
    a: 'Yes, our API is specifically designed for developers who want to integrate our solution into their applications.',
  },
  {
    q: 'Do you have a free trial?',
    a: (
      <p>
        Yes, upon signing up for our platform, you receive 20 free credits to
        use with our interface.{' '}
        <a
          className="underline font-medium"
          href="https://app.fashn.ai"
          target="_blank"
        >
          Sign up now
        </a>
        .
      </p>
    ),
  },
  {
    q: 'Can anyone use FASHN?',
    a: 'Yes, the FASHN platform is designed to be accessible by users of all technical levels. Upon signing up, you can easily navigate our user-friendly interface, which requires no prior technical knowledge. Alternatively, you can engage with our solution programmatically through our API.',
  },
  {
    q: 'Do you support models of all ethnicities?',
    a: 'Yes, our AI model is trained on a carefully collected, extensive dataset featuring fashion models from a wide range of ethnicities. This diversity ensures that our technology is well-equipped to handle and accurately represent models of all backgrounds.',
  },
  {
    q: 'Are you able to fit clothes accurately on all body shapes and sizes?',
    a: 'There are inherent challenges in perfectly fitting and resizing clothes using just one reference image. For greater accuracy, our enterprise plan allows you to fine-tune our AI, ensuring it adapts perfectly to your models, regardless of their size or shape.',
  },
];

const Faq: React.FunctionComponent = () => {
  return (
    <section
      className="px-4 xl:px-0 text-center py-10 md:py-20 w-full flex flex-col gap-10 bg-foreground"
      id="faq"
    >
      <h2 className={cn(headFont.className, 'text-2xl tracking-widest')}>
        faq
      </h2>
      <span>
        Didn&apos;t get your answer? Feel free to contact us at{' '}
        <Button
          className="p-0 h-fit"
          variant={'ghost'}
          onClick={() => copyToClipboard('info@fashn.ai')}
        >
          info@fashn.ai
        </Button>
      </span>
      <div className="flex flex-col xl:flex-row justify-between max-w-7xl mx-auto w-full gap-10 xl:gap-0 items-center">
        <Accordion
          type="single"
          collapsible
          className="text-left w-full md:max-w-lg"
        >
          {questions.map((question, i) => (
            <AccordionItem
              value={`question-${i + 1}`}
              key={`question-${i + 1}`}
            >
              <AccordionTrigger>{question.q}</AccordionTrigger>
              <AccordionContent className="opacity-80 md:max-w-lg">
                {question.a}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
        <div className="grid grid-cols-3 gap-4">
          <img
            loading="lazy"
            src={faqModel}
            alt={'FAQ model'}
            className="w-48 h-auto rounded-md"
          />
          <img
            loading="lazy"
            src={faqGarment}
            alt={'FAQ garment'}
            className="w-48 h-auto rounded-md"
          />
          <img
            loading="lazy"
            src={faqResult}
            alt={'FAQ result'}
            className="w-48 h-auto rounded-md"
          />
        </div>
      </div>
    </section>
  );
};

export default Faq;
